
$picker-option-text-color: #000;
$picker-option-disabled-opacity: 0.3;
$picker-option-font-size: 16px;
.picker-column {
    flex: 1;
    overflow: hidden;
    font-size: $picker-option-font-size;
    .ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .wrapper {
        transition-timing-function: cubic-bezier(0.23, 1, 0.68, 1);
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 4px;
        color: $picker-option-text-color;

        &.disabled {
            cursor: not-allowed;
            opacity: $picker-option-disabled-opacity;
        }
    }
}
