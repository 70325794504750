
.register-questions-container {
    height: 100%;
    h2 {
        flex-shrink: 0;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        color: #191919;
        text-align: center;
    }
    .register-questions-list {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .register-questions-item {
            display: flex;
            align-items: center;
            gap: 15px;
            width: 100%;
            padding: 25px 15px;
            border: 1px solid rgba(25, 25, 25, 0.1);
            border-radius: 12px;
            cursor: pointer;
            .register-questions-icon {
                flex-shrink: 0;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 20px;
                text-align: center;
                position: relative;
                &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background: var(--color-theme);
                    opacity: 0.1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                i {
                    color: var(--color-theme);
                    opacity: 1;
                }
            }

            .register-questions-label {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #191919;
            }
            &:hover,
            &.active {
                border-color: var(--color-theme);
            }
        }
    }
    .previous-btn {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a8a8a8;
        margin-top: 150px;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
