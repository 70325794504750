
@import './transition.scss';
.popper {
    z-index: var(--level-high);
    .arrow,
    .arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
        background: inherit;
    }

    .arrow {
        visibility: hidden;
    }
    .arrow::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
        border: 1px solid;
        border-color: inherit;
    }
}
.popper[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
    border-top-color: transparent;
    border-left-color: transparent;
}

.popper[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
    border-right-color: transparent;
    border-bottom-color: transparent;
}

.popper[data-popper-placement^='left'] > .arrow {
    right: -4px;
    border-left-color: transparent;
    border-bottom-color: transparent;
}

.popper[data-popper-placement^='right'] > .arrow {
    left: -4px;
    border-top-color: transparent;
    border-right-color: transparent;
}
