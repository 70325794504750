
@import '~@/theme/media.scss';
.pop {
    &.fadeIn {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &.fadeIn-enter-active,
    &.fadeIn-leave-active {
        transition: opacity 0.3s ease;
    }
    &.fadeIn-enter,
    &.fadeIn-leave-to {
        opacity: 0;
    }

    &.bottomToUp {
        top: auto;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 0);
    }
    &.bottomToUp-enter-active,
    &.bottomToUp-leave-active {
        transition: transform ease 0.3s;
    }
    &.bottomToUp-enter,
    &.bottomToUp-leave-to {
        transform: translate(-50%, 100%);
    }
    &.leftToRight {
        top: 50%;
        left: 0;
        right: auto;
        transform: translate(0, -50%);
    }
    &.leftToRight-enter-active,
    &.leftToRight-leave-active {
        transition: transform ease 0.3s;
    }
    &.leftToRight-enter,
    &.leftToRight-leave-to {
        transform: translate(-100%, -50%);
    }
    &.fadeInRight {
        top: 50%;
        left: auto;
        right: 0;
        transform: translate(0, -50%);
    }
    &.fadeInRight-enter-active,
    &.fadeInRight-leave-active {
        transition: transform ease 0.3s;
    }
    &.fadeInRight-enter,
    &.fadeInRight-leave-to {
        transform: translate(100%, -50%);
    }

    &.scale {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        transition: all ease 0.3s;
    }
    &.scale-enter-active,
    &.scale-leave-active {
        transition: all ease 0.3s;
    }
    &.scale-enter {
        transform: translate(-50%, -50%) scale(0.6);
        opacity: 1;
    }
    &.scale-leave-to {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}
