
.im-select {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    .ct-select {
        .drop_icon {
            display: flex;
            align-content: center;
            justify-content: center;
            margin-right: 10px;
            @media screen and (max-width: 800px) {
                padding-right: 17px;
            }
            .iconfont {
                transition: transform 0.3s;
                &.rotate {
                    transform: rotate(180deg);
                }
            }
        }
        .labels {
            display: flex;
            justify-content: flex-start;
            overflow: hidden;
            max-width: 100%;
            .label-pd {
                padding-left: 10px;
            }
            .ellipse {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        &.standard {
            .iconfont {
                font-size: 16px;
                color: #6b7282;
            }
        }
        &.template4 {
            .iconfont {
                font-size: 18px;
                color: #8b93a7;
                font-weight: bold;
            }
        }
        &.template1 {
            .iconfont {
                color: #6b7282;
            }
        }
        &.broker {
            .iconfont {
                color: #6b7282;
            }
        }
    }
    // search
    .searchInput {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #e2e4ea;
        font-size: 16px;
        margin-bottom: 5px;
        &.standard {
            border-bottom-color: #e2e4ea;
            .searchIcon {
                font-size: inherit;
                color: #c6c8d1;
            }
        }
        &.template1 {
            border-bottom-color: #dcdcdc;
            .searchIcon {
                font-size: inherit;
                color: #505050;
            }
        }
        &.template4 {
            border-bottom-color: #e2e4ea;
            .searchIcon {
                font-size: inherit;
                color: #6b7282;
            }
        }

        .input {
            flex: 1 0 0;
            border: none;
            box-shadow: none;
            height: 100%;
            padding-left: 5px;
        }
    }
    .help-item {
        padding: 0;
        height: 40px;
        line-height: 40px;
        display: flex;
    }
    .customInputClass {
        cursor: pointer;
        &[readonly] {
            color: var(--color-text);
        }
    }
}

// dropdown  animation
$--im-fade-transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1),
    opacity 300ms cubic-bezier(0.23, 1, 0.32, 1) !default;
.zoom-in-top-enter {
    opacity: 0;
    transform: scaleY(0);
}
.zoom-in-top-enter-active,
.zoom-in-top-leave-active {
    transform: scaleY(1);
    opacity: 1;
    transform-origin: center top;
    transition: $--im-fade-transition;
}
.zoom-in-top-leave-active {
    transform: scaleY(0);
    opacity: 0;
}
/*  animation  */
$--im-fade-transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1),
    opacity 500ms cubic-bezier(0.23, 1, 0.32, 1) !default;
.fade-enter {
    opacity: 0;
    transform: scale(0, 0);
}
.fade-enter-active,
.fade-leave-active {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: center center;
    transition: $--im-fade-transition;
}
.fade-leave-active {
    opacity: 0;
    transform: scale(0, 0);
}
