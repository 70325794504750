@import './media.scss';
@import './mixin.scss';
@import './layout.scss';
@import './animated.scss';
@import './global-var.scss';

// public style
* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-touch-callout: none;
}

.seo {
    display: none;
}

:root{
    --module-width: 1340px;
    @include media-min-1500 {
        --module-width: 90vw;
    }
}

html {
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    font-family: sans-serif;
}

body {
    font-family: var(--font-normal);
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    // height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: 14px;
    @include media-1080 {
        font-size: 12px;
    }
}
main {
    background: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 1em;
}

h3,
h4,
h5,
h6 {
    font-weight: 500;
}

.skiptranslate {
    display: none !important;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
figure,
li {
    padding: 0;
    margin: 0;
}

ul,
ol {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    border: none;
    cursor: pointer;
}

input,
textarea,
select {
    font-family: var(--font-normal);
    outline: none;
    background-color: #fff;
    appearance: none;
    -webkit-appearance: none;
    box-shadow: var(--box-shadow);
    border: solid 1px var(--color-border);
    border-radius: var(--radius-input, 0px);
}

textarea {
    resize: none;
    overflow: auto;
}

input[type='submit'],
button {
    outline: none;
    cursor: pointer;
    box-shadow: none;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    border-radius: var(--radius-button, 0px);
    font-family: var(--mg-button-font, var(--font-bold));
}

input[type='submit']:focus {
    border: initial;
}

input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
}

input[type='checkbox'] {
    appearance: checkbox;
    -webkit-appearance: checkbox;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    background-color: #fff;
    box-shadow: 0 0 0 1000px white inset;
}

input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none;
    pointer-events: none;
    position: absolute;
    right: 0;
}

input[readonly],
textarea[readonly] {
    color: #999999;
    box-shadow: none;
}

::-webkit-search-cancel-button {
    display: none;
}

::-webkit-input-placeholder {
    color: var(--color-holder);
    font-size: 14px;
}

::-moz-placeholder {
    color: var(--color-holder);
    font-size: 14px;
}

::-ms-input-placeholder {
    color: var(--color-holder);
    font-size: 14px;
}

form {
    margin: 0;
}

a,
a:hover,
a:visited,
a:active {
    color: inherit;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    background: none;
}

a[href='javascript:void(0);'],
a[href=''],
a:not([href]) {
    cursor: default;
}

img {
    border: none;
}

img:not([src]),
img[src=''] {
    visibility: hidden;
    border: none;
    min-width: 1px;
}

[tabindex],
video:focus {
    outline: none;
}

.clearfix {
    zoom: 1;
}

.clearfix:after {
    content: ' ';
    display: table;
    clear: both;
}

.overflow-hidden {
    overflow: hidden !important;
}

.hidden-scrollbar {
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
    }
}
.no-wrap {
    white-space: nowrap;
}
.md-error {
    display: none;
    width: 100%;
    height: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    font-size: 30px;
    color: var(--color-error);
    border: 2px solid var(--color-border);
    &.development {
        display: flex;
    }
}

.mg-scrollbar {
    ::-webkit-scrollbar {
        width: 0;
    }
    ::-webkit-scrollbar-track-piece {
        background-color: transparent;
        border-radius: 0;
    }
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-thumb {
        height: 50px;
        background-color: transparent;
        border-radius: 4px;
        outline: 2px solid #fff;
        outline-offset: -2px;
        border: 2px solid #fff;
    }
    :hover {
        &::-webkit-scrollbar-thumb {
            background: #999;
        }
    }
}

.mg-loading {
    background-image: url('//static.chimeroi.com/site/images/loading-20x20.gif');
    background-size: cover;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.mg-title-richtext {
    font-family: var(--mg-title-font);
    font-size: var(--mg-title-size);
    color: var(--mg-title-color);
    text-align: var(--mg-title-align);
    line-height: var(--mg-title-line-height);
    letter-spacing: var(--mg-title-letter-spacing);
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    padding-bottom: 20px;
    * {
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
    }
    @include media-600 {
        font-size: var(--mg-title-size-mobile);
        line-height: var(--mg-title-line-height-mobile);
        letter-spacing: var(--mg-title-letter-spacing-mobile);
    }
}

.f-col {
    @include module-f-col;
}
.f-col-2 {
    @include module-f-col-2;
}
.f-pl {
    @include module-f-padding(padding-left);
}
.f-pr {
    @include module-f-padding(padding-right);
}
.f-ml {
    @include module-f-padding(margin-left);
}
.f-mr {
    @include module-f-padding(margin-right);
}
.full-space {
    width: calc(100% - 100px);
    margin-left: 50px;
    margin-right: 50px;
    overflow: hidden;
    @include media-600 {
        width: calc(100% - 30px);
        margin-left: 15px;
        margin-right: 15px;
    }
}

.mg-title {
    max-width: 100%;
    margin: 0 auto;
    @extend .mg-title-richtext;
    @include module-width;
}

.mg-more {
    font-family: var(--font-normal);
    font-size: 14px;
    float: right;
    letter-spacing: 0;
    position: relative;
    cursor: pointer;
    @include color-theme();
    > .icon-next {
        transform: scale(0.7);
        font-weight: 700;
        float: right;
        font-size: 12px;
    }
    &:hover {
        @include color-hover();
    }
}
a.mg-more {
    @include color-theme();
    &:hover {
        @include color-hover();
    }
}

.mg-error {
    font-size: 12px;
    color: var(--color-error);
    clear: left;
}

.mg-required {
    margin-left: 5px;
    font-size: 16px;
    line-height: 0px;
    vertical-align: middle;
}

.mg-color-bg {
    @include color-theme('background-color');
}

.mg-color {
    @include color-theme();
}

.mg-color-border {
    @include color-theme('border-color');
}

.mg-topspacing {
    padding-top: 40px;
}
.mg-btmspacing {
    padding-bottom: 40px;
}

.mg-container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    @include module-width;
    @include media-600 {
        &.mobile-no-margin {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.page-content {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
   // overflow-x: hidden;
    > * {
        position: relative;
    }
    > *:not(.mg-bg) {
        background: #fff;
    }
    &.preview-in-cms {
        min-height: 100vh;
    }
}

//  Listing details watermark
.test-site.listing-detail,
.test-site.listing-detail-sold {
    .page-content {
        &:after {
            position: absolute;
            content: '';
            background-image: url('//static.chimeroi.com/site/images/sample-list.png');
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-repeat: repeat;
            pointer-events: none;
            z-index: 1;
        }
        .md-footer {
            z-index: 2;
        }

        //  listing details ， Listing cards hide watermarks
        .house-list-item,
        .house-grid-item {
            &:after {
                display: none;
            }
        }
    }
}

.empty-container {
    font-size: 14px;
    color: #a0a3af;
    line-height: 18px;
    margin-top: 80px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(//static.chimeroi.com/am/empty-block.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    p {
        margin-top: -30px;
        padding: 0 30px;
    }
}

.loading-box {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .loading-text {
        margin-top: 10px;
        width: 100px;
        height: 30px;
        font-size: 16px;
        text-align: center;
        color: rgb(160, 163, 175);
        &::before {
            content: 'Loading';
            content: attr(label);
        }
    }
}
.loading-anim {
    position: relative;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 5px solid #e9e9e9;
    background-color: #ffffff;
    border-right-color: #59aefc;
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
    animation: loading 2s infinite linear;
    -webkit-animation: loading 2s infinite linear;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.loading-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
}
.loading-wrap {
    position: absolute;
    width: 100px;
    padding: 10px;
    overflow: hidden;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(170, 186, 218, 0.2);
    background-color: #ffffff;
    .loading {
        font-size: 14px;
        color: #a0a3af;
        margin-top: 10px;
        text-align: center;
    }
}
.ws-pre-wrap {
    white-space: pre-wrap;
}
.m0 {
    margin: 0;
}
.p0 {
    padding: 0;
}
.icon-google2:before {
    content: '' !important;
    background-image: url('https://cdn.chime.me/image/fs/sitebuild/202379/22/original_453f1718-a549-43c4-8dbb-4d45fee98a70.png');
    background-repeat: no-repeat;
    background-size: 16px auto;
    width: 100%;
    height: 100%;
    display: block;
    background-position: center;
    background-color: hsl(0, 0%, 90%);
    border-radius: 50%;
}
#fb-root.hide-chat {
    display: none;
}

.mg-link {
    color: var(--color-link, #4c84ff);
}
.mg-bg {
    background-color: var(--g-bg-color);
}
