
@import '../../theme/mixin.scss';

.img-box {
    position: relative;
    overflow: hidden;

    .loading-box {
        position: absolute;
        background-color: #ffffff;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .img-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        > img {
            width: 100%;
            height: 100%;
            @include object-fit(cover);
        }
    }
}
