
.register-phone-valid-container {
    h2.title {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
        color: #191919;
        margin-top: 40px;
    }
    h3.subTitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #505050;
        margin: 15px auto 30px;
    }
    .phone-valid-img {
        text-align: center;
        margin-bottom: 30px;
        #svgImg {
            fill: var(--color-theme);
        }
    }
    .phone-valid-content {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .intro {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            .intro-text {
                font-size: 12px;
                line-height: 20px;
                color: #505050;
            }
            .register-phone-number {
                font-size: 14px;
                line-height: 20px;
                color: #191919;
            }
        }
        .verification-code-wrap {
            .verification-code-title {
                text-align: center;
                font-size: 12px;
                line-height: 20px;
                color: #505050;
                margin-bottom: 10px;
            }
            .verification-code {
                display: flex;
                justify-content: space-between;
                .code {
                    width: 48px;
                    height: 48px;
                    border: 1px solid #dadada;
                    outline: none;
                    box-shadow: none;
                    border-radius: 5px;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 30px;
                    color: #191919;
                    text-align: center;
                }
            }
        }
        .send-to-phone {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            line-height: 20px;
            color: #505050;
            user-select: none;
            .send-btn {
                font-weight: 600;
                color: var(--color-theme);
                padding: 0 3px;
                text-decoration: underline;
                cursor: pointer;
                &[disabled='true'] {
                    opacity: 0.4;
                    cursor: no-drop;
                }
            }
            .countdown {
                width: 32px;
                color: var(--color-theme);
            }
        }
        .register-phone-button-wrap {
            display: flex;
            gap: 20px;
            .button-back,
            .button-next {
                flex: 1;
                height: 40px;
                padding: 10px 20px;
                font-size: 16px;
                border-radius: 5px;
                &:hover {
                    &::after {
                        display: none;
                    }
                }
                &[disabled] {
                    opacity: 0.4;
                    cursor: no-drop;
                }
            }
        }
        .later-text {
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: var(--color-theme);
            margin-bottom: 30px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
