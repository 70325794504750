
.md-lazy {
    width: 100%;
    height: 300px;
    position: relative;

    &.md-header {
        height: 60px;

        .loading-text {
            display: none;
        }
    }

    &.md-search,
    &.md-hero {
        height: 100vh;
    }
}
