
.register-describe-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    h2 {
        flex-shrink: 0;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        color: #191919;
        margin-bottom: 20px;
    }
    .describe-list {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .describe-item {
            width: 100%;
            padding: 20px 0;
            text-align: center;
            border: 1px solid transparent;
            border-radius: 12px;
            cursor: pointer;
            .describe-icon {
                .svgImg {
                    fill: var(--color-theme);
                }
            }

            .label {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #191919;
                margin-top: 30px;
            }
            &:hover,
            &.active {
                border-color: var(--color-theme);
            }
        }
    }
}
