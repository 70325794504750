
.im-select-dropdown {
    min-width: 300px;
    padding-top: 2px;
    position: absolute;
    top: 100%;
    width:100%;
    z-index: var(--level-high);
    background: #fff;
    max-height: 300px;
    overflow: auto;
    &.drop_left{
        left: 0;
    }
    &.drop_right{
        right: 0;
    }
    &::-webkit-scrollbar {
        width: 0 !important;
    }
    .im-sel-container {
        width: 100%;
        border-radius: 2px;
        padding: 0 10px;
    }
    &.standard {
        border: solid 1px #ebecf1;
    }
    &.template4 {
        border: solid 1px #d6dae8;
    }
    &.template1 {
        border: solid 1px #d5d5d5;
    }
    &.broker{
        box-shadow: 2px 2px 10px 0 rgba(0, 10, 30, 0.1);
    }
}
