
$picker-background-color: #fff;
$picker-toolbar-height: 44px;
$picker-title-font-size: 16px;
$picker-title-line-height: 20px;
$picker-action-padding: 0 8px;
$picker-action-font-size: 14px;
$picker-confirm-action-color: #576b95;
$picker-cancel-action-color: #969799;
$picker-option-disabled-opacity: 0.3;
$picker-loading-icon-color: #1989fa;
$picker-loading-mask-color: rgba(255, 255, 255, 0.9);
$active-opacity: 0.7;
$font-weight-bold: 500;
.im-m-picker {
    .toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $picker-toolbar-height;
    }
    .cancel,
    .confirm {
        height: 100%;
        padding: $picker-action-padding;
        font-size: $picker-action-font-size;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:active {
            opacity: $active-opacity;
        }
    }

    .confirm {
        color: $picker-confirm-action-color;
    }

    .cancel {
        color: $picker-cancel-action-color;
    }
    .title {
        max-width: 50%;
        font-weight: $font-weight-bold;
        font-size: $picker-title-font-size;
        line-height: $picker-title-line-height;
        text-align: center;
    }
    .ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $picker-loading-icon-color;
        background-color: $picker-loading-mask-color;
    }

    .frame {
        position: absolute;
        top: 50%;
        right: 8px;
        left: 8px;
        z-index: 3;
        transform: translateY(-50%);
        pointer-events: none;
        &::after {
            position: absolute;
            box-sizing: border-box;
            content: ' ';
            pointer-events: none;
            top: -50%;
            bottom: -50%;
            left: -50%;
            right: -50%;
            border: 0 solid #ebedf0;
            transform: scale(0.5);
            border-width: 1px 0;
        }
    }

    .mask {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
                180deg,
                hsla(0, 0%, 100%, 0.9),
                hsla(0, 0%, 100%, 0.4)
            ),
            linear-gradient(
                0deg,
                hsla(0, 0%, 100%, 0.9),
                hsla(0, 0%, 100%, 0.4)
            );
        background-repeat: no-repeat;
        background-position: top, bottom;
        backface-visibility: hidden;
        pointer-events: none;
    }
    .columns {
        position: relative;
        display: flex;
        cursor: grab;
    }
}
