
.video-box.fb {
    position: relative;
    width: 100%;
    height: 100%;
    &.mobile {
        z-index: 2;
    }
    .video-wrap {
        height: 100%;
        width: 100%;
         .fb-video {
            width: 100% !important;
            height: 100% !important;
            text-align: center;
            span {
                width: 100% !important;
                height: 100% !important;
                display:flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .video-action {
        position: absolute;
        .iconfont {
            position: absolute;
            width: 36px;
            height: 36px;
            left: 20px;
            bottom: 20px;
            font-size: 16px;
            color: #fff;
            border-radius: 4px;
            z-index: 9;
            cursor: pointer;
            text-align: center;
            vertical-align: middle;
            line-height: 36px;
            &::after {
                background: #282828;
                opacity: 0.3;
                border-radius: 4px;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                content: '';
                z-index: -1;
            }
        }
    }
}
