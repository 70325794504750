
@import '~@/theme/media.scss';
@import '~@/theme/mixin.scss';

.md-goto-top {
    .btn-top {
        position: fixed;
        z-index: var(--level-sublow, 10);
        // bottom: 18px;
        left: 18px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        cursor: pointer;
        @include color-theme('background-color');
        @include media-800 {
            left: auto;
            right: 18px;
        }
        .icon-arrow-down {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);
            color: white;
            font-weight: bold;
            font-size: 18px;
        }
    }
}
