
@import '@/theme/media.scss';
.md-evaluation-banner.copyright {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: space-around;
    padding: 7px 0 5px;
    background-color: rgba(25, 25, 25, 0.7);
    color: #fff;
    font-family: var(--font-medium);
    font-weight: normal;
    z-index: 3;
    .chime {
        .link {
            text-decoration: underline;
        }
        .reserved {
            display: block;
        }
    }
    .chime,
    .service,
    .policy {
        padding: 0 15px;
    }
    @include media-800 {
        height: 100px;
        background-color: rgba(0, 0, 0, 0.3);
        flex-direction: column;
        text-align: left;
        justify-content: center;
    }
}
.no-footer-has-copyright {
    padding-bottom: 85px;
}
