
.site-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 2px 20px 0;
    border: 1px solid;
    position: relative;
    font-family: var(--mg-button-font);
    box-sizing: border-box;
    &.normal {
        border-color: var(--g-btn-background);
        background-color: var(--g-btn-background);
        color: var(--g-btn-color);
    }
    &.plain {
        background-color: transparent;
        border-color: var(--g-btn-background);
        color: var(--g-btn-background);
    }
    &:hover {
        &::after {
            content: '';
            background: rgba(25, 25, 25, 0.1);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
