
@import '@/theme/media.scss';
@import '@/theme/mixin.scss';
.out-sharing-pop{
    .pop-mask{
        z-index: calc(var(--level-high) - 1);
    }
    .pop{
        line-height: 1.5;
        background: #fff;
        width: 720px;
        z-index: var(--level-high);
    }
    .out-sharing-content{
        padding: 30px;
    }
    .out-sharing-title{
        text-transform: uppercase;
        border-bottom: 1px solid #dadada80;
        line-height: 70px;
        font-size: 20px;
        font-family: var(--font-bold);
        padding-left: 30px;
        padding-right: 30px;
    }
    .site-switch{
        width: 40px;
        height: 20px;
        border-radius: 10px;
        flex-shrink: 0;
        .site-switch-cursor{
            width: 16px;
            height: 16px;
            transform: translate(2px, 2px);
            border: 0px solid;
        }
        &.on{
            background: var(--g-primary-color);
            .site-switch-cursor{
                transform: translate(-2px, 2px);
            }
        } 
    }
    .out-sharing-div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .out-sharing-desc{
            font-size: 14px;
            font-family: var(--font-bold);
            color: #191919;
        }
    }
    .out-sharing-p{
        font-size: 12px;
        color: #505050;
        
    }
    .out-sharing-note-title{
        color: #505050;
        font-size: 12px;
        font-family: var(--font-bold);
        margin-bottom: 10px;
        font-size: 14px;
    }
    .out-sharing-note{
        border: 1px solid #dadada80;
        background: #dadada19;
        padding: 10px;
        border-radius: 4;
        margin-top: 30px;
        .out-sharing-p{
            display: flex;
            .disc{
                width: 5px;
                height: 5px;
                background: #A8A8A8;
                border-radius: 50%;
                flex-shrink: 0;
                flex-grow: 0;
                margin-right: 10px;
                margin-top: 6px;
            }
            .desc{
                flex: 1;
            }
        }
        .out-sharing-p + .out-sharing-p{
            margin-top: 10px;
        }
    }
    .out-sharing-footer{
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #dadada80;
        font-size: 14px;
        line-height: 1.5;
        padding: 20px 30px;
        .cancel{
            color: #505050;
            cursor: pointer;
            width: 100px;
            height: 40px;
            margin-right: 10px;
            background: transparent;
        }
        .confirm{
            color: #fff;
            background: var(--g-primary-color);
            width: 100px;
            height: 40px;
        }
    }
    @include media-600{
        .pop{
            width: 345px;
        }
    }
}

