
.im-select-option {
    cursor: pointer;
    .item {
        /* padding: 0 10px 0 20px; */
        padding: 0;
        height: 40px;
        line-height: 40px;
        display: flex;
        .label {
            flex: 1 0 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .icon {
            /* display: none; */
            /* padding: 0 12px; */
            display: flex;
            align-items: center;
            justify-content: center;
            /* .iconfont {
                font-size: 12px;
            } */
        }
        &.selected{
            color: var(--color-theme);
        }
        &.standard.disabled,
        &.template4.disabled,
        &.template1.disabled {
            color: #e4e7ed;
        }
    }
}
