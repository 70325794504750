// from element ui
$--all-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !default;
$--fade-transition: opacity 300ms cubic-bezier(0.23, 1, 0.32, 1) !default;
$--fade-linear-transition: opacity 200ms linear !default;
$--md-fade-transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1),
    opacity 300ms cubic-bezier(0.23, 1, 0.32, 1) !default;

.fade-in-linear-enter-active,
.fade-in-linear-leave-active {
    transition: $--fade-linear-transition;
}
.fade-in-linear-enter,
.fade-in-linear-leave,
.fade-in-linear-leave-active {
    opacity: 0;
}

.el-fade-in-linear-enter-active,
.el-fade-in-linear-leave-active {
    transition: $--fade-linear-transition;
}
.el-fade-in-linear-enter,
.el-fade-in-linear-leave,
.el-fade-in-linear-leave-active {
    opacity: 0;
}

.el-fade-in-enter-active,
.el-fade-in-leave-active {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}
.el-fade-in-enter,
.el-fade-in-leave-active {
    opacity: 0;
}

.el-zoom-in-center-enter-active,
.el-zoom-in-center-leave-active {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}
.el-zoom-in-center-enter,
.el-zoom-in-center-leave-active {
    opacity: 0;
    transform: scaleX(0);
}

.el-zoom-in-top-enter-active,
.el-zoom-in-top-leave-active {
    opacity: 1;
    transform: scaleY(1);
    transition: $--md-fade-transition;
    transform-origin: center top;
}
.el-zoom-in-top-enter,
.el-zoom-in-top-leave-active {
    opacity: 0;
    transform: scaleY(0);
}

.el-zoom-in-bottom-enter-active,
.el-zoom-in-bottom-leave-active {
    opacity: 1;
    transform: scaleY(1);
    transition: $--md-fade-transition;
    transform-origin: center bottom;
}
.el-zoom-in-bottom-enter,
.el-zoom-in-bottom-leave-active {
    opacity: 0;
    transform: scaleY(0);
}

.el-zoom-in-left-enter-active,
.el-zoom-in-left-leave-active {
    opacity: 1;
    transform: scale(1, 1);
    transition: $--md-fade-transition;
    transform-origin: top left;
}
.el-zoom-in-left-enter,
.el-zoom-in-left-leave-active {
    opacity: 0;
    transform: scale(0.45, 0.45);
}
