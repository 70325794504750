
.site-switch {
    width: 24px;
    height: 10px;
    border-radius: 5px;
    display: inline-flex;
    background: #d0d0d0;
    justify-content: flex-start;
    margin: 0px 8px;
    cursor: pointer;
    .site-switch-cursor {
        width: 12px;
        height: 12px;
        background: #fff;
        border: solid 1px #ff781d;
        border-radius: 50%;
        transform: translateY(-1px);
    }
    &.on {
        background: #ff781d;
        justify-content: flex-end;
    }
}
