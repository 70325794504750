
@import '@/theme/mixin.scss';
.new-register-phone-valid-pop {
    .new-register-phone-valid-container {
        width: 400px;
        min-height: 600px;
        box-sizing: border-box;
        padding: 0 30px;
        margin: 0 auto;
        overflow: hidden;
        background-color: #ffffff;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        @include media-600 {
            width: 100%;
            padding: 0 15px;
        }
    }
    .register-finish-page {
        min-height: 600px;
    }
    .pop {
        @include media-600 {
            width: calc(100% - 30px);
        }
        .icon-close {
            top: 15px;
            right: 20px;
        }
    }
}
