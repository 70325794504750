
.register-finish-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .finish-page-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .finish-page-img {
            flex-shrink: 0;
            margin-bottom: 30px;
        }
        .finish-page-title {
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            text-align: center;
            color: #191919;
        }
        .finish-page-subTitle {
            margin-top: 20px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #505050;
        }
    }
    .finish-page-button {
        width: 100%;
        margin: 30px 0;
    }
    .site-button {
        height: 40px;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 5px;
    }
}
