
.intall-closely-plugin {
    height: 60px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px -4px 10px rgba(0, 10, 30, 0.1);
    z-index: calc(var(--level-subhigh) - 3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    .closely-icon {
        display: flex;
        align-items: center;
        .iconfont.icon-close-light {
            font-size: 16px;
            color: #a8a8a8;
            margin-right: 15px;
        }
        .app-name {
            color: #191919;
            font-size: 16px;
            line-height: 20px;
            font-family: var(--font-medium);
            margin-left: 8px;
            white-space: nowrap;
        }
        img {
            width: 17px;
            height: 20px;
        }
    }
    .install-plugin {
        background: #3b6ef1;
        font-family: var(--font-bold);
        border-radius: 5px;
        display: block;
        color: #fff;
        line-height: 40px;
        font-size: 14px;
        padding: 0 20px;
    }
}
