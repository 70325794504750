
@import '../../../theme/media';

.site-subtitle {
    font-family: var(--mg-sub-title-font);
    font-size: var(--mg-sub-title-size);
    color: var(--g-text-color);
    text-align: var(--mg-sub-title-align);
    line-height: var(--mg-sub-title-line-height);   
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    &.left{
        text-align: left;
    }
    &.center{
        text-align: center;
    }
    &.right{
        text-align: right;
    }
    @include media-600 {
        font-size: var(--mg-sub-title-size-mobile);
        line-height: var(--mg-sub-title-line-height-mobile);
    }
    h1,h2,h3,h4,h5,h6{
        margin: 0;
        padding: 0;
        line-height: inherit;
    }
}

