
@import '../../../theme/media';

.site-title {
    font-family: var(--mg-title-font);
    color: var(--g-primary-color);
    text-align: var(--mg-title-align);
    line-height: var(--mg-title-line-height);
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    &.left {
        text-align: left;
    }
    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
    @include media-600 {
        line-height: var(--mg-title-line-height-mobile);
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
        line-height: inherit;
    }
}
