
.comp-im-checkbox {
    position: relative;
    overflow: hidden;
    .label {
        cursor: pointer;
        width: 15px;
        height: 15px;
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #ebecf1;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        .checkicon {
            font-size: 0;
            color: var(--color-theme);
        }
    }
    .im-checkbox-ele {
        position: absolute;
        z-index: -100;
        left: -99999px;
        right: -99999px;
        &:checked + .label {
            .checkicon {
                font-size: 13px;
            }
        }
    }
}
