
.video-box.ytb {
    position: relative;
    width: 100%;
    height: 100%;
    .video-wrap {
        width: 100%;
        height: 100%;
        iframe {
            max-width: none;
            width: 100%;
            height: 100%;
        }
    }
}
