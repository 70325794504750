
@import '@/theme/mixin.scss';
.register-questions-pop {
    .pop {
        .icon-close {
            z-index: 100;
        }
        @include media-600 {
            width: calc(100% - 30px);
        }
    }
}

.register-questions-pop .questions-container {
    position: relative;
    width: 400px;
    min-height: 560px;
    margin: 0 auto;
    @include media-600 {
        width: 100%;
    }
    .questions-bg {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 530px;
        padding: 40px 30px;
        box-sizing: border-box;
    }
    .questions-bg2 {
        position: absolute;
        z-index: 2;
        width: calc(100% - 40px);
        height: 525px;
        margin: 20px 20px 0;
    }
    .questions-bg3 {
        position: absolute;
        z-index: 1;
        width: calc(100% - 80px);
        height: 520px;
        margin: 40px 40px 0;
    }
    .questions-bg,
    .questions-bg2,
    .questions-bg3 {
        top: 0;
        left: 0;
        right: 0;
        border-radius: 24px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 5px 65px 0px rgba(0, 0, 0, 0.1) inset,
            0px 5px 15px 0px rgba(0, 0, 0, 0.1) inset,
            0px 5px 15px 0px rgba(0, 0, 0, 0.1) inset,
            0px 5px 15px 0px rgba(0, 0, 0, 0.03);
    }
    .register-finish-page {
        justify-content: center;
        .finish-page-content {
            flex: none;
        }
        .finish-page-button {
            margin-bottom: 0px;
        }
    }
}
