
/*  styles for different themes  */
/*  layout  */
.input_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    background: #fff;
    &.standard {
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #ebecf1;
    }
    &.broker {
        border: solid 1px #dadada;
        border-radius: 5px;
    }
    &.template4 {
        border: solid 2px #d6dae8;
    }
    &.template1 {
        border-radius: 2px;
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #d5d5d5;
    }
    &.standard.readonly {
        .input {
            color: #202437;
        }
    }
    &.template4.readonly {
        .input {
            color: #4a4a60;
        }
    }
    &.template1.readonly {
        .input {
            color: #202437;
        }
    }
    &:hover,
    &.focus {
        border-color: var(--color-theme);
    }
    &.disabled {
        border: solid 1px #ebecf1;
        background: #f8f9fd;
        &.hover,
        &.focus {
            border: solid 1px #ebecf1;
        }
    }
    .input_wrap {
        position: relative;
        flex: 1 0 0;
        display: flex;
        height: 100%;
        .input {
            width: 100%;
            height: 100%;
            outline: none;
            border: 0 !important;
            box-shadow: none;
            border-radius: 0;
            background: transparent;
            box-sizing: border-box;
            padding: 0 10px;
            /* Eliminate global style interference */
            height: 100% !important;
            background-color: transparent !important;
            box-shadow: none !important;
            font-size: 14px;
            min-height: 30px;
            &.rpad35 {
                padding-right: 35px;
            }
            &.lpad35 {
                padding-left: 45px;
            }
            &.left {
                text-align: left;
            }
            &.center {
                text-align: center;
            }
            &.right {
                text-align: right;
            }
            ::placeholder {
                line-height: normal;
            }
        }
        .search {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            height: 100%;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cccccc;
            cursor: pointer;
        }
        .clear {
            position: absolute;
            z-index: 1;
            right: 0;
            top: 0;
            cursor: pointer;
            height: 100%;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cccccc;
        }
    }
}

