:root {
    --box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    --label-font-size: 14px;
    --radius-input: 0px;
    /*------- level ---------*/
    --level-low: 1; /* general module */
    --level-sublow: 100; /* taller module */
    --level-medium: 200; /*header,chatbox,get more info wait for floating windows etc. */
    --level-subhigh: 300; /* bullet box ， select list */
    --level-high: 400; /*loading,toast wait */

    --mg-title-size-mobile: 20px;
	--mg-title-line-height: 1.5;
	--mg-title-line-height-mobile: 1.5;
	--mg-title-letter-spacing: 0;
	--mg-title-letter-spacing-mobile: 0;
	--mg-sub-title-font: var(--font-medium);
	--mg-sub-title-size: 16px;
	--mg-sub-title-size-mobile: 14px;
	--mg-sub-title-align: left;
	--mg-sub-title-line-height: 1.5;
	--mg-sub-title-line-height-mobile: 1.5;
	--color-success: #20c472;
	--color-error: #f0454c;
	--color-link: #4c84ff;
	--color-title: #2d4251;
	--color-text: #505050;
	--color-label: #505050;
	--color-info: #505050;
	--color-disable: #a8a8a8;
	--color-holder: #c6c8d1;
	--mg-title-color: var(--g-primary-color);
	--mg-button-color: var(--g-btn-color);
	--mg-button-background: var(--g-btn-background);
	--color-theme: var(--g-btn-background);
	--color-border: #dadada;
}
