.fade-in {
    transition: opacity ease-out;
}
.fade-in-enter-from {
    opacity: 0;
}
.fade-in-enter-to {
    opacity: 1;
}

.shift-left {
    transition: transform ease, opacity ease;
}
.shift-left-enter-from {
    transform: translateX(100%);
    opacity: 0;
}
.shift-left-enter-to {
    transform: translateX(0);
    opacity: 1;
}

.shift-right {
    transition: transform ease, opacity ease;
}
.shift-right-enter-from {
    transform: translateX(-100%);
    opacity: 0;
}
.shift-right-enter-to {
    transform: translateX(0);
    opacity: 1;
}

.appear-up {
    transition: clip-path ease;
}
.appear-up-enter-from {
    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
}
.appear-up-enter-to {
    clip-path: none;
}

.appear-down {
    transition: clip-path ease, transform ease, opacity ease;
}
.appear-down-enter-from {
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    transform: translate3d(0, 100%, 0);
    opacity: 0;
}
.appear-down-enter-to {
    clip-path: none;
    transform: translate3d(0, 0, 0);
    opacity: 1;
}