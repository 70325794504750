@mixin media-min-1920 {
    @media (min-width: 1921px) {
        @content;
    }
}
@mixin media-1920 {
    @media (max-width: 1920px) {
        @content;
    }
}
@mixin media-min-1680 {
    @media (min-width: 1681px) {
        @content;
    }
}
@mixin media-1680 {
    @media (max-width: 1680px) {
        @content;
    }
}
@mixin media-min-1500 {
    @media (min-width: 1500px) {
        @content;
    }
}

@mixin media-1440 {
    @media (max-width: 1440px) {
        @content;
    }
}
@mixin media-min-1440 {
    @media (min-width: 1441px) {
        @content;
    }
}

@mixin media-min-1280 {
    @media (min-width: 1281px) {
        @content;
    }
}

@mixin media-1280 {
    @media (max-width: 1280px) {
        @content;
    }
}

@mixin media-1260 {
    @media (max-width: 1260px) {
        @content;
    }
}

@mixin media-1200 {
    @media (max-width: 1260px) {
        @content;
    }
}

@mixin media-1080 {
    @media (max-width: 1080px) {
        @content;
    }
}
@mixin media-min-1080 {
    @media (min-width: 1081px) {
        @content;
    }
}

@mixin media-840 {
    @media (max-width: 840px) {
        @content;
    }
}

@mixin media-800 {
    @media (max-width: 800px) {
        @content;
    }
}

/* define minimum width 800， Need to be with media-800 correspond */
@mixin media-min-800 {
    @media (min-width: 801px) {
        @content;
    }
}

@mixin media-640 {
    @media (max-width: 640px) {
        @content;
    }
}

@mixin media-610 {
    @media (max-width: 610px) {
        @content;
    }
}

@mixin media-600 {
    @media (max-width: 600px) {
        @content;
    }
}

@mixin media-min-600 {
    @media (min-width: 601px) {
        @content;
    }
}

@mixin media-375 {
    @media (max-width: 375px) {
        @content;
    }
}

@mixin media-320 {
    @media (max-width: 320px) {
        @content;
    }
}
