@import './media.scss';

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null) {
    -o-object-fit: $fit;
    object-fit: $fit;

    @if $position {
        -o-object-position: $position;
        object-position: $position;
    }
}

@mixin block-title {
    font-family: var(--font-bold);
    font-size: 24px;
    letter-spacing: 0;
    color: var(--color-text);
    @include media-600 {
        font-size: var(--mg-title-size-mobile);
    }
}

@mixin block-subtitle {
    font-family: var(--font-medium);
    font-size: var(--mg-sub-title-size);
    letter-spacing: 0;
    color: var(--color-label);
    @include media-600 {
        font-size: var(--mg-sub-title-size-mobile);
    }
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin webkitBox($lineClamp) {
    display: -webkit-box;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lineClamp;
    word-break: break-word;
}
@mixin webkitBoxHide($lineClamp) {
    display: -webkit-box;
    white-space: normal;
    overflow: hidden;
    // text-overflow: ellipsis;
    /*! autoprefixer: ignore next */
    // -webkit-box-orient: vertical;
    -webkit-line-clamp: $lineClamp;
    word-break: break-word;
}

@mixin center-block {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin placeholderColor($color) {
    ::-webkit-input-placeholder {
        color: $color;
    }

    ::-moz-placeholder {
        color: $color;
    }

    ::-ms-input-placeholder {
        color: $color;
    }
}

@mixin placeholderMix($font, $color, $size: 14px) {
    input::-webkit-input-placeholder {
        color: $color;
        font-family: $font;
        font-size: $size;
        text-overflow: ellipsis;
    }
    input::-moz-placeholder {
        color: $color;
        font-family: $font;
        font-size: $size;
        text-overflow: ellipsis;
    }
    input:-ms-input-placeholder {
        color: $color !important;
        font-family: $font;
        font-size: $size;
        text-overflow: ellipsis;
    }
}

$color-theme: #03c9ff !default;
$color-hover: #03c9ff !default;

// theme blue
@mixin color-theme($name: 'color', $value: null, $default: $color-theme) {
    #{$name}: $value var(--color-theme, $default);
}
@mixin color-hover($name: 'color', $value: null, $default: $color-hover) {
    #{$name}: $value var(--color-hover, $default);
}
@mixin color-button-v2($bgcolor: #03c9ff, $color: #ffffff) {
    background-color: var(
        --g-btn-background,
        var(--mg-button-background, $bgcolor)
    );
    border-color: var(
        --g-btn-background,
        var(--mg-button-background, $bgcolor)
    );
    color: var(--g-btn-color, var(--mg-button-color, $color));
}
@mixin color-button($bgcolor: #03c9ff, $color: #ffffff) {
    background-color: var(--mg-button-background, $bgcolor);
    border-color: var(--mg-button-background, $bgcolor);
    color: var(--mg-button-color, $color);
}
@mixin color-button-border($value: 1px solid, $bgcolor: #03c9ff) {
    border: $value var(--mg-button-background, $bgcolor);
}
@mixin color-button-hover($radius: null) {
    position: relative;
    &:hover {
        &::after {
            content: '';
            background: rgba(25, 25, 25, 0.1);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @if $radius {
                border-radius: $radius;
            }
        }
    }
}
@mixin color-button-color($color: #03c9ff) {
    color: var(--mg-button-background, $color);
}

@mixin color-button-interaction(
    $bgcolor: #03c9ff,
    $hoverlayer: rgba(255, 255, 255, 0.1),
    $activelayer: rgba(0, 0, 0, 0.1)
) {
    background-color: $bgcolor;
    &:hover {
        background: linear-gradient($hoverlayer, $hoverlayer),
            linear-gradient($bgcolor, $bgcolor);
    }
    &:active {
        background: linear-gradient($activelayer, $activelayer),
            linear-gradient($bgcolor, $bgcolor);
    }
}

@mixin status-color($p: 'color') {
    #{$p}: #93b9e3;
    &.open-house {
        #{$p}: #45adff;
    }
    &.hot {
        #{$p}: #f3a843;
    }
    &.new {
        #{$p}: #62b280;
    }
    &.reduced {
        #{$p}: #ef784f;
    }
    &.price-reduced {
        #{$p}: #ef784f;
    }
    &.coming-soon,
    &.off-market {
        #{$p}: #93b9e3;
    }
}

@mixin input-disabled($opacity: 0.4) {
    opacity: $opacity !important;
    &:hover {
        opacity: $opacity;
    }
}
@mixin always-show-scroll($thumb-color: #999, $width: 4px, $height: 8px) {
    &::-webkit-scrollbar {
        width: $width;
        height: $height;
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $thumb-color;
    }
}

@mixin flex($direction, $justify: 'flex-start', $align: 'center') {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

@mixin inline-flex($direction, $justify: 'flex-start', $align: 'center') {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

@mixin ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@mixin module-width {
    width: var(--module-width, 1000px);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include media-1080 {
        width: calc(100vw - 80px);
    }
    @include media-840 {
        width: 760px;
    }
    @include media-800 {
        width: calc(100vw - 40px);
    }
    @include media-600 {
        width: calc(100vw - 30px);
    }
}

@mixin module-f-col {
    width: var(--module-width, 1000px);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include media-1440 {
        width: calc(100vw - 100px);
    }
    @include media-1080 {
        width: calc(100vw - 80px);
    }
    @include media-840 {
        width: calc(100vw - 60px);
    }
    @include media-800 {
        width: calc(100vw - 40px);
    }
    @include media-600 {
        width: calc(100vw - 30px);
    }
}

@mixin module-f-col-2 {
    width: calc(var(--module-width, 1000px) / 2);
    max-width: 100%;
    @include media-1440 {
        width: calc(50vw - 50px);
    }
    @include media-1080 {
        width: calc(50vw - 40px);
    }
    @include media-840 {
        width: calc(50vw - 30px);
    }
    @include media-800 {
        width: calc(50vw - 20px);
    }
    @include media-600 {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@mixin module-f-padding($padding-key) {
    #{$padding-key}: calc((100vw - var(--module-width, 1000px)) / 2);
    @include media-1440 {
        #{$padding-key}: 50px;
    }
    @include media-1080 {
        #{$padding-key}: 40px;
    }
    @include media-840 {
        #{$padding-key}: 30px;
    }
    @include media-800 {
        #{$padding-key}: 20px;
    }
    @include media-600 {
        #{$padding-key}: 15px;
    }
}

@mixin adapter($list...) {
    @each $property, $pc_value, $mobile_value in $list {
        #{$property}: $pc_value;
    }
    @include media-600 {
        @each $property, $pc_value, $mobile_value in $list {
            #{$property}: $mobile_value;
        }
    }
}

@mixin polyfill-root-var {
    :root{
        --color-success: #20c472;
        --color-link: #4c84ff;
        --g-bg-color: #ffffff;
        --g-primary-color: var(--mg-title-color);
        --g-text-color: var(--color-label);
        --g-tip-color: var(--color-info);
        --g-btn-color: var(--mg-button-color);
        --g-btn-background: var(--mg-button-background);
    }
}
@mixin polyfill-f-col {
    .mg-title,
    .mg-container {
        @media (min-width: 1080px) and (max-width: 1440px) {
            width: calc(100vw - 100px);
        }
    }
    .f-col-2 {
        @media (min-width: 1080px) and (max-width: 1440px) {
            width: calc(50vw - 50px);
        }
    }
}
