
.module-selected {
    position: relative;
    overflow: visible !important;
    .cms-module-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: solid 2px var(--primary-color);
        background-color: var(--primary-transparent-20);
        z-index: 5000;
    }
    .cms-module-operation-button{
        font-family: font-normal, font-light, Helvetica Neue, Helvetica, Verdana, Arial;
        display: block;
        .module-editor {
            display: flex;
            align-items: center;
        }
    }
}
.cms-module-operation-button {
    .module-editor {
        padding: 0 15px;
        border-radius: 4px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        height: 40px;
        line-height: 40px;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 5001;
        display: none;
        > .iconfont {
            cursor: pointer;
            text-align: center;
            height: 40px;
            line-height: 40px;
            display: inline-block;
            flex: 1;
            position: relative;
            color: #797e8b;
            font-size: 18px;
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
            &:hover{
                color: var(--primary-color);
            }
            &.icon-delete:hover {
                color: #f0454c;
            }
        }
        .icon-handle {
            cursor: move;
            font-size: 14px;
            margin-top: 2px;
        }
        .more-operation {
            display: flex;
            align-items: center;
            position: relative;
            padding: 0 0 0 15px;
            &:after {
                content: '';
                background-color: #ebecf1;
                width: 1px;
                height: 20px;
                position: absolute;
                left: 0;
            }
            .more-operation-list {
                .iconfont {
                    color: #797e8b;
                    font-size: 18px;
                    cursor: pointer;
                    &:hover {
                        color: var(--primary-color);
                    }
                }
                .more-list {
                    position: absolute;
                    width: 160px;
                    padding: 10px;
                    right: -15px;
                    border-radius: 2px;
                    background-color: #fff;
                    top: calc(100% + 4px);
                    li {
                        font-size: 0;
                        padding: 0px 10px;
                        cursor: pointer;
                        border-radius: 2px;
                        line-height: 32px;
                        height: 36px;
                        color: #515666;
                        text-align: left;
                        span {
                            font-size: 14px;
                            margin-left: 10px;
                        }
                        .iconfont {
                            font-size: 16px;
                            margin: 0;
                            &.icon-Vector:before{
                                transform: translateY(1px);
                                display: inline-block;
                            }
                        }
                        &:hover {
                            background: #f6f7fb;
                        }
                    }
                }
            }
        }
    }
}
