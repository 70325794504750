
.an-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .mask{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(25, 25, 25, 0.6);
        z-index: var(--level-subhigh);
        transition: opacity 0.3s ease;
    }
    .container{
        position: absolute;
        transition: all 0.3s ease;
        display: inline-block;
        z-index: calc(var(--level-subhigh) + 1);
    }
}

.fade-in-enter-from .mask,
.fade-in-leave-to .mask,
.right-in-enter-from .mask,
.right-in-leave-to .mask,
.fade-in-enter-from .container,
.fade-in-leave-to .container {
    opacity: 0;
}


.right-in.container{
    right: 0;
}
.right-in-enter-from .container,
.right-in-leave-to .container {
    transform: translate3d(100%, 0, 0);
}


