
@import '../../../theme/media';

.site-p {
    color: var(--g-text-color);
    line-height: 1.5;
    &.normal{
        font-family: var(--font-normal);
    }
    &.light{
        font-family: var(--font-light);
    }
    &.bold{
        font-family: var(--font-bold);
    }
    &.left {
        text-align: left;
    }
    &.center {
        text-align: center;
    }
    &.right {
        text-align: right;
    }
}
